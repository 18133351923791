import { createApi } from '@reduxjs/toolkit/query/react'

import { AUTH_ANONYMOUS, AUTH_LOGIN, AUTH_LOGOUT } from '@/config/endpoints'
import { apiClientId, apiClientSecret } from '@/config/env'
import { AuthAnonymousSessionResponse, AuthLoginRequest, AuthLoginResponse } from '@/types/auth'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'auth',
  tagTypes: ['Auth'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    anonymous: builder.mutation<AuthAnonymousSessionResponse, void>({
      query: () => ({
        ...composeRequest({}, AUTH_ANONYMOUS),
        headers: {
          Authorization: `Basic ${btoa(`${apiClientId}:${apiClientSecret}`)}`,
        },
        method: 'POST',
      }),
    }),
    login: builder.mutation<AuthLoginResponse, AuthLoginRequest>({
      query: (request) => ({
        ...composeRequest(request, AUTH_LOGIN),
        method: 'POST',
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        ...composeRequest({}, AUTH_LOGOUT),
        method: 'DELETE',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const { useAnonymousMutation, useLoginMutation, useLogoutMutation, util: authUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
