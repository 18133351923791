import styled, { CSSObject } from '@emotion/styled'
import React, { FC } from 'react'

const ErrorWrapper = styled('div')(
  (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 16px)',
    padding: 0,
    margin: 0,
    background: '#1A2238',
    fontFamily: 'Inter',
  })
)

const ErrorCode = styled('h1')(
  (): CSSObject => ({
    fontSize: '8rem',
    lineHeight: 1,
    fontWeight: 800,
    letterSpacing: '0.1em',
    color: '#ffffff',
    userSelect: 'none',
  })
)

const ErrorMessage = styled('p')(
  (): CSSObject => ({
    position: 'absolute',
    transform: 'rotate(12deg)',
    userSelect: 'none',
    borderRadius: '0.25rem',
    backgroundColor: '#ff6a3d',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  })
)

type ErrorProps = {
  code: number
  message: string
}

const Error: FC<ErrorProps> = ({ code, message }) => {
  return (
    <ErrorWrapper>
      <ErrorCode>{code}</ErrorCode>

      <ErrorMessage>{message}</ErrorMessage>
    </ErrorWrapper>
  )
}

export default Error
