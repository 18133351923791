import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Admin } from '@/types/admin'

type ProfileState = {
  data?: Admin
  privileges?: string[]
}

const initialState: ProfileState = {}

export const profileSlice = createSlice({
  initialState,
  name: 'profile',
  reducers: {
    setProfileData: (state, action: PayloadAction<Admin>) => {
      state.data = action.payload
    },
    clearProfileData: (state) => {
      state.data = undefined
    },
    setProfilePrivileges: (state, action: PayloadAction<string[]>) => {
      state.privileges = action.payload
    },
    clearProfilePrivileges: (state) => {
      state.privileges = undefined
    },
  },
})

export const { setProfileData, clearProfileData, setProfilePrivileges, clearProfilePrivileges } = profileSlice.actions

export default profileSlice.reducer
