import { createRouter, RouterProvider } from '@tanstack/react-router'
import React from 'react'

import { appBasePath } from '@/config/env'
import AbilityContext from '@/contexts/AbilityContext'
import useAppSelector from '@/hooks/useAppSelector'
import routeTree from '@/routes'
import { ability } from '@/utils/ability'

const router = createRouter({
  routeTree,
  basepath: appBasePath,
  caseSensitive: true,
  defaultPreload: 'intent',
  context: {
    title: '',
    // Guard
    signed: false,
    guest: false,
  },
})

const App = () => {
  const { privileges } = useAppSelector((state) => state.profile)

  return (
    <AbilityContext.Provider value={ability(privileges || [])}>
      <RouterProvider router={router} />
    </AbilityContext.Provider>
  )
}

export default App
