export enum SortBy {
  Latest = 'latest',
  Earliest = 'earliest',
  LastUpdated = 'last-updated',
}

export enum AdminStatus {
  Active = 1,
}

export enum UserStatus {
  Active = 1,
}

export enum ReportStatus {
  Active = 2,
  Resolved,
  Closed,
}

export enum ReportCommentStatus {
  Active = 1,
  Inactive,
}

export enum ReportSortBy {
  Title = 'title-asc',
  MostVoted = 'most-voted',
  MostCommented = 'most-commented',
}

export enum ReportCategory {
  Food = 1,
  Accommodation,
  Transportation,
  MissingPerson,
  LostItem,
  Health,
  Other,
}

export enum Actions {
  Access = 'access',
  Manage = 'manage',
}

export enum Abilities {
  Dashboard = 'D',
}

export enum MediaType {
  Image = 1,
  Video = 2,
}

export enum UserType {
  Unverified = 1,
  Jamaah = 2,
  Petugas = 3,
  Admin = 4,
}
