import admin from '@/services/admin'
import auth from '@/services/auth'
import dashboard from '@/services/dashboard'
import report from '@/services/report'
import user from '@/services/user'

const rootServices = {
  reducers: {
    // auth
    [auth.reducerPath]: auth.reducer,

    // admin
    [admin.reducerPath]: admin.reducer,

    // dashboard
    [dashboard.reducerPath]: dashboard.reducer,

    // report
    [report.reducerPath]: report.reducer,

    // user
    [user.reducerPath]: user.reducer,
  },
  middlewares: [
    // auth
    auth.middleware,

    // admin
    admin.middleware,

    // dashboard
    dashboard.middleware,

    // report
    report.middleware,

    // user
    user.middleware,
  ],
}

export default rootServices
