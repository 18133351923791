import { createApi } from '@reduxjs/toolkit/query/react'

import { REPORT, REPORT_COMMENT, REPORT_COMMENT_STATUS, REPORT_DETAIL, REPORT_STATUS } from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  ReportCommentListRequest,
  ReportCommentListResponse,
  ReportCommentUpdateStatusRequest,
  ReportCreateCommentRequest,
  ReportDetailResponse,
  ReportListRequest,
  ReportListResponse,
  ReportUpdateStatusRequest,
} from '@/types/report'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'report',
  tagTypes: ['Report', 'ReportDetail', 'ReportComment'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListReport: builder.query<ReportListResponse, ReportListRequest>({
      query: (request) => ({
        ...composeRequest(request, REPORT),
        method: 'GET',
      }),
      providesTags: ['Report'],
    }),
    getDetailReport: builder.query<ReportDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, REPORT_DETAIL),
        method: 'GET',
      }),
      providesTags: ['ReportDetail'],
    }),
    updateStatusReport: builder.mutation<void, ReportUpdateStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, REPORT_STATUS),
        method: 'PATCH',
      }),
      invalidatesTags: ['Report', 'ReportDetail'],
    }),
    deleteReport: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, REPORT_DETAIL),
        method: 'DELETE',
      }),
      invalidatesTags: ['Report'],
    }),
    getListReportComment: builder.query<ReportCommentListResponse, ReportCommentListRequest>({
      query: (request) => ({
        ...composeRequest(request, REPORT_COMMENT),
        method: 'GET',
      }),
      providesTags: ['ReportComment'],
    }),
    updateStatusReportComment: builder.mutation<void, ReportCommentUpdateStatusRequest>({
      query: (request) => ({
        ...composeRequest(request, REPORT_COMMENT_STATUS),
        method: 'PATCH',
      }),
      invalidatesTags: ['ReportComment'],
    }),
    createReportComment: builder.mutation<void, ReportCreateCommentRequest>({
      query: (request) => ({
        ...composeRequest(request, REPORT_COMMENT),
        method: 'POST',
      }),
      invalidatesTags: ['ReportComment'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListReportQuery,
  useLazyGetDetailReportQuery,
  useUpdateStatusReportMutation,
  useDeleteReportMutation,
  useLazyGetListReportCommentQuery,
  useUpdateStatusReportCommentMutation,
  useCreateReportCommentMutation,
  util: reportUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
