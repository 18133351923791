import { ErrorComponentProps } from '@tanstack/react-router'
import React from 'react'
import { useDocumentTitle } from 'usehooks-ts'

import Error from '@/components/Error'
import { appName } from '@/config/env'

const InternalError = (error: ErrorComponentProps) => {
  useDocumentTitle(`Internal Server Error - ${appName}`)

  return (
    <Error
      code={500}
      message={(error?.error as any)?.message || 'Internal Server Error'}
    />
  )
}

export default InternalError
