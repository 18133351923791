import { createRootRouteWithContext, createRoute, lazyRouteComponent, notFound, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import context from '@/middlewares/context'
import InternalError from '@/modules/error/internal-error'
import NotFound from '@/modules/error/not-found'
import { RouteContext } from '@/types/route'
import { isHasUserAccessToken } from '@/utils/app'

// Begin: Root Route
const rootRoute = createRootRouteWithContext<RouteContext>()({
  component: lazyRouteComponent(() => import('@/layouts/BaseLayout')),
  notFoundComponent: NotFound,
  errorComponent: InternalError,
})

const rootBlankRoute = createRoute({
  id: 'blank',
  component: lazyRouteComponent(() => import('@/layouts/BlankLayout')),
  getParentRoute: () => rootRoute,
})

const rootAuthRoute = createRoute({
  id: 'auth',
  component: lazyRouteComponent(() => import('@/layouts/AuthLayout')),
  getParentRoute: () => rootRoute,
  beforeLoad: ({ search, location }) => {
    const requiredTokenPaths = ['/auth/reset-password', '/auth/setup-password']

    if (requiredTokenPaths.includes(location.pathname)) {
      try {
        z.object({ t: z.string() }).parse(search)
      } catch (_e) {
        throw notFound()
      }
    }
  },
})

const rootDashboardRoute = createRoute({
  id: 'dashboard',
  component: lazyRouteComponent(() => import('@/layouts/DashboardLayout')),
  getParentRoute: () => rootRoute,
})
// End: Root Route

// Begin: Blank Layout
const healthCheckRoute = createRoute({
  path: '/_health',
  component: lazyRouteComponent(() => import('@/modules/health')),
  getParentRoute: () => rootBlankRoute,
  beforeLoad: (opts) => {
    return context(
      {
        // content
        title: 'Health Check',
      },
      opts.location
    )
  },
})
// End: Blank Layout

// Begin: Auth Layout
const loginRoute = createRoute({
  path: '/auth/login',
  component: lazyRouteComponent(() => import('@/modules/auth/login')),
  getParentRoute: () => rootAuthRoute,
  beforeLoad: (opts) => {
    return context(
      {
        // content
        title: 'Masuk',
        subTitle: 'Masuk',
        // guard
        guest: true,
      },
      opts.location
    )
  },
})
// End: Auth Layout

// Begin: Dashboard Layout
const mainRoute = createRoute({
  path: '/',
  getParentRoute: () => rootDashboardRoute,
  beforeLoad: ({ location }) => {
    if (isHasUserAccessToken()) {
      throw redirect({
        to: '/dashboard',
      })
    }

    throw redirect({
      to: '/auth/login',
      search: {
        redirect: location.href !== '/' ? location.href : undefined,
      },
    })
  },
})

const dashboardRoute = createRoute({
  path: '/dashboard',
  component: lazyRouteComponent(() => import('@/modules/dashboard')),
  getParentRoute: () => rootDashboardRoute,
  beforeLoad: (opts) => {
    return context(
      {
        // content
        title: 'Dashboard',
        breadcrumbs: [
          {
            path: '/dashboard',
            label: 'Dashboard',
          },
        ],
        // permission
        // action: Actions.Access,
        // ability: Abilities.Dashboard,
        // guard
        signed: true,
      },
      opts.location
    )
  },
})

const reportListRoute = createRoute({
  path: '/reports',
  component: lazyRouteComponent(() => import('@/modules/report/list')),
  getParentRoute: () => rootDashboardRoute,
  beforeLoad: (opts) => {
    return context(
      {
        // content
        title: 'Daftar Laporan',
        breadcrumbs: [
          {
            path: '/reports',
            label: 'Daftar Laporan',
          },
        ],
        // permission
        // action: Actions.Access,
        // ability: Abilities.Report,
        // guard
        signed: true,
      },
      opts.location
    )
  },
})

const reportDetailRoute = createRoute({
  path: '/reports/$xid',
  component: lazyRouteComponent(() => import('@/modules/report/detail')),
  getParentRoute: () => rootDashboardRoute,
  beforeLoad: (opts) => {
    return context(
      {
        // content
        title: 'Detail Laporan',
        breadcrumbs: [
          {
            path: '/reports',
            label: 'Daftar Laporan',
          },
          {
            path: `/reports/${opts.params.xid}`,
            label: 'Detail Laporan',
          },
        ],
        backPath: '/reports',
        // permission
        // action: Actions.Access,
        // ability: Abilities.Report,
        // guard
        signed: true,
      },
      opts.location
    )
  },
})

const userListRoute = createRoute({
  path: '/users',
  component: lazyRouteComponent(() => import('@/modules/user/list')),
  getParentRoute: () => rootDashboardRoute,
  beforeLoad: (opts) => {
    return context(
      {
        // content
        title: 'Daftar Pengguna',
        breadcrumbs: [
          {
            path: '/users',
            label: 'Daftar Pengguna',
          },
        ],
        // permission
        // action: Actions.Access,
        // ability: Abilities.Report,
        // guard
        signed: true,
      },
      opts.location
    )
  },
})

const userDetailRoute = createRoute({
  path: '/users/$xid',
  component: lazyRouteComponent(() => import('@/modules/user/detail')),
  getParentRoute: () => rootDashboardRoute,
  beforeLoad: (opts) => {
    return context(
      {
        // content
        title: 'Detail Pengguna',
        breadcrumbs: [
          {
            path: '/users',
            label: 'Daftar Pengguna',
          },
          {
            path: `/users/${opts.params.xid}`,
            label: 'Detail Pengguna',
          },
        ],
        backPath: '/users',
        // permission
        // action: Actions.Access,
        // ability: Abilities.Report,
        // guard
        signed: true,
      },
      opts.location
    )
  },
})

const userDetailTrackingRoute = createRoute({
  path: '/users/$xid/tracking',
  component: lazyRouteComponent(() => import('@/modules/user/tracking')),
  getParentRoute: () => rootDashboardRoute,
  beforeLoad: (opts) => {
    return context(
      {
        // content
        title: 'Tracking Lokasi Jamaah',
        breadcrumbs: [
          {
            path: '/users',
            label: 'Daftar Pengguna',
          },
          {
            path: `/users/${opts.params.xid}`,
            label: 'Detail Pengguna',
          },
          {
            path: `/users/${opts.params.xid}/tracking`,
            label: 'Tracking Lokasi Jamaah',
          },
        ],
        backPath: `/users/${opts.params.xid}`,
        // permission
        // action: Actions.Access,
        // ability: Abilities.Report,
        // guard
        signed: true,
      },
      opts.location
    )
  },
})
// End: Dashboard Layout

export default rootRoute.addChildren([
  // route using blank layout
  rootBlankRoute.addChildren([
    // health check
    healthCheckRoute,
  ]),
  // route using auth layout
  rootAuthRoute.addChildren([
    // login
    loginRoute,
  ]),
  // route using dashboard layout
  rootDashboardRoute.addChildren([
    mainRoute,

    // dashboard
    dashboardRoute,

    // report
    reportListRoute,
    reportDetailRoute,

    // user
    userListRoute,
    userDetailRoute,
    userDetailTrackingRoute,
  ]),
])
