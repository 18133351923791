import { DateTime } from 'luxon'

export const tailingSlash = (url: string) => url.replace(/\/$/, '')

export const formatEpoch = (seconds: number, format: string, locale = 'ID') =>
  DateTime.fromSeconds(seconds).setLocale(locale).toFormat(format)

export const formatMillis = (millis: number, format: string, locale = 'ID') =>
  DateTime.fromMillis(millis).setLocale(locale).toFormat(format)

export const formatNumber = (number: number) =>
  new Intl.NumberFormat('id-ID', { minimumFractionDigits: 0 }).format(number)
