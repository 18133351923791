import { createApi } from '@reduxjs/toolkit/query/react'

import {
  USER,
  USER_DETAIL,
  USER_DETAIL_LIST_TRACKING,
  USER_UPDATE_JAMAAH,
  USER_UPDATE_PETUGAS,
} from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  UserDetailResponse,
  UserListRequest,
  UserListResponse,
  UserListTrackingRequest,
  UserListTrackingResponse,
  UserUpdateStatusPetugasRequest,
} from '@/types/user'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'user',
  tagTypes: ['User', 'UserDetail', 'UserTracking'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListUser: builder.query<UserListResponse, UserListRequest>({
      query: (request) => ({
        ...composeRequest(request, USER),
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getDetailUser: builder.query<UserDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_DETAIL),
        method: 'GET',
      }),
      providesTags: ['UserDetail'],
    }),
    getListUserTracking: builder.query<UserListTrackingResponse, UserListTrackingRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_DETAIL_LIST_TRACKING),
        method: 'GET',
      }),
      providesTags: ['UserTracking'],
    }),
    updateUserJamaah: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_UPDATE_JAMAAH),
        method: 'PATCH',
      }),
      invalidatesTags: ['UserDetail'],
    }),
    updateUserPetugas: builder.mutation<void, UserUpdateStatusPetugasRequest>({
      query: (request) => ({
        ...composeRequest(request, USER_UPDATE_PETUGAS),
        method: 'PATCH',
      }),
      invalidatesTags: ['UserDetail', 'User'],
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListUserQuery,
  useLazyGetDetailUserQuery,
  useLazyGetListUserTrackingQuery,
  useUpdateUserJamaahMutation,
  useUpdateUserPetugasMutation,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
