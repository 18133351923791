import React from 'react'
import { useDocumentTitle } from 'usehooks-ts'

import Error from '@/components/Error'
import { appName } from '@/config/env'

const NotFound = () => {
  useDocumentTitle(`Page Not Found - ${appName}`)

  return (
    <Error
      code={404}
      message='Page Not Found'
    />
  )
}

export default NotFound
