import { createApi } from '@reduxjs/toolkit/query/react'

import { DASHBOARD_REPORT_COUNT, DASHBOARD_USER_COUNT } from '@/config/endpoints'
import { DashboardReportCountRequest, DashboardReportCountResponse, DashboardUserCountRequest } from '@/types/dashboard'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'dashboard',
  tagTypes: [],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getReportCount: builder.query<DashboardReportCountResponse, DashboardReportCountRequest>({
      query: (request) => ({
        ...composeRequest(request, DASHBOARD_REPORT_COUNT),
        method: 'GET',
      }),
    }),
    getUserCount: builder.query<DashboardReportCountResponse, DashboardUserCountRequest>({
      query: (request) => ({
        ...composeRequest(request, DASHBOARD_USER_COUNT),
        method: 'GET',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const { useGetReportCountQuery, useGetUserCountQuery } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
