// Auth
export const AUTH_ANONYMOUS = 'v1/admins/anon/sessions'
export const AUTH_LOGIN = 'v1/admins/sessions'
export const AUTH_LOGOUT = 'v1/admins/me/sessions'

// Admin
export const ADMIN_ME = 'v1/admins/me'

// Dashboard
export const DASHBOARD_REPORT_COUNT = 'v1/admins/stats/reports/count'
export const DASHBOARD_USER_COUNT = 'v1/admins/stats/users/count'

// Report
export const REPORT = 'v1/users/reports'
export const REPORT_DETAIL = 'v1/users/reports/:xid'
export const REPORT_STATUS = 'v1/users/reports/:xid/status'
export const REPORT_COMMENT = 'v1/users/reports/:xid/comments'
export const REPORT_COMMENT_STATUS = 'v1/users/reports/comments/:id/status'

// User
export const USER = 'v1/admins/users'
export const USER_DETAIL = 'v1/admins/users/:xid'
export const USER_DETAIL_LIST_TRACKING = 'v1/admins/users/:xid/location'
export const USER_UPDATE_JAMAAH = 'v1/admins/users/:xid/jamaah'
export const USER_UPDATE_PETUGAS = 'v1/admins/users/:xid/user-type/petugas'
