import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LayoutState = {
  page: {
    loading: boolean
    pathname: string
  }
  sidebar: {
    open: boolean
    collapsed: boolean
  }
}

const initialState: LayoutState = {
  page: {
    loading: false,
    pathname: '/',
  },
  sidebar: {
    open: false,
    collapsed: false,
  },
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLayoutPagLoading: (state, action: PayloadAction<boolean>) => {
      state.page.loading = action.payload
    },
    setLayoutPagPathName: (state, action: PayloadAction<string>) => {
      state.page.pathname = action.payload
    },
    setLayoutSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebar.open = action.payload
    },
    setLayoutSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebar.collapsed = action.payload
    },
  },
})

export const { setLayoutPagLoading, setLayoutPagPathName, setLayoutSidebarOpen, setLayoutSidebarCollapsed } =
  layoutSlice.actions

export default layoutSlice.reducer
